import { useEffect, useState, useContext } from "react";
import AuthContext from "./context/auth-context";

const NetworkStatus = () => {
  const show = useContext(AuthContext);

  const [status, setStatus] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);

      if (navigator.onLine) {
        show.setNetWorkStatusCtxt(true);
      } else {
        show.setNetWorkStatusCtxt(false);
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, [show]);

  return status ? true : false;
};

export default NetworkStatus;
