import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useBreakPoint } from "../../hook/useBreakPoint";
export const SectionTitle = ({ name }) => {
  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  return (
    <div className="px-0 mt-4">
      <div
        className={clsx("text-light title position-relative m-auto pb-3", {
          "fs-1": !isMobile,
          "fs-4": isMobile,
        })}
      >
        {name}
        <span className="border-title-bottom"></span>
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  name: PropTypes.string.isRequired,
};
