import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useBreakPoint } from "../../hook/useBreakPoint";
export const InputField = ({
  id,
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  errorMessage,
  maxLength,
  disabled,
  isTouched,
}) => {
  useEffect(() => {
    document.getElementById(id).focus();
  }, [id]);

  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null;
    }
    return errorMessage && "border-danger border";
  }, [errorMessage, isTouched]);

  return (
    <div className="px-0 input-field mx-auto">
      <input
        className={clsx(
          "text-center text-uppercase w-100 py-2 input_field",
          validationClassName,
          {
            "fs-3": !isMobile,
            "fs-6": isMobile,
          }
        )}
        id={id}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={true}
        name={id}
        type={type}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isTouched && errorMessage && (
        <p
          className={clsx("text-danger text-start mt-2", {
            "fs-6": !isMobile,
            tinyText: isMobile,
          })}
          role="alert"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

InputField.prototype = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "button" |
      "checkbox" |
      "datetime-local" |
      "email" |
      "file" |
      "hidden" |
      "submit" |
      "text" |
      "time",
  ]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
};

InputField.defaultProps = {
  maxLength: 1000,
  disabled: false,
};
