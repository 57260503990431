import React, { useMemo } from "react";
import clsx from "clsx";
import { useBreakPoint } from "../../hook/useBreakPoint";
export const CustomButton = ({
  isSubmitting,
  disabled,
  onClick,
  type,
  title,
  label,
  classes,
  style,
}) => {
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);
  return (
    <button
      disabled={disabled ? disabled : false}
      type={type}
      onClick={onClick && onClick}
      className={clsx(
        [
          "btn btn-sm rounded mt-2 px-4 text-uppercase  mainButton",
          classes,
        ].join(" "),
        {
          "fs-4": !isMobile,
        }
      )}
      aria-label={label}
      style={style}
    >
      <span>{title}</span>
      {isSubmitting && (
        <div className="spinner-border ms-3" role="status"></div>
      )}
    </button>
  );
};
