import { useCallback, useMemo } from "react";
import { Toast } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toasterActions } from "../../_redux/ToasterRedux";

export const ToasterModal = () => {
  const toasterModal = useSelector((state) => state.toasterModal);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(toasterActions.toaster.reset());
  }, [dispatch]);

  const toasterName = useMemo(() => {
    if (toasterModal?.toaster) {
      if (typeof toasterModal.toaster?.message === "string")
        return toasterModal.toaster.message;
      else {
        if (toasterModal.toaster?.message["error"])
          return toasterModal.toaster?.message["error"];
        else if (toasterModal.toaster?.message?.data["error"])
          return toasterModal.toaster?.message.data["error"];
        else return "Error";
      }
    }
    return null;
  }, [toasterModal]);

  return toasterModal && toasterModal.toaster ? (
    <div
      className="position-fixed"
      style={{
        bottom: "30px",
        right: "30px",
      }}
    >
      <Toast
        className="d-inline-block m-1 text-light d-flex"
        show={toasterModal.toaster.show}
        bg={toasterModal.toaster.variant}
        delay={toasterModal.toaster.delay || 3000}
        autohide
        onClose={handleClose}
      >
        <Toast.Body className="Dark">{toasterName}</Toast.Body>
      </Toast>
    </div>
  ) : null;
};
