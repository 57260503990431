import axios from "axios";

const API = process.env.REACT_APP_API_URL;

const SCANNER_PATH = "scan";

const HEADER = {
  headers: {
    "x-organization": process.env.REACT_APP_ORGANIZATION,
  },
};

export const GetScannerByCode = async (code) =>
  await axios.get(`${API}/${SCANNER_PATH}/${code}`, HEADER);

export const GetBadgeCollection = async (payload) =>
  await axios.post(API + "/scan/badge-collection", payload.payload, {
    headers: {
      "x-organization": process.env.REACT_APP_ORGANIZATION,
      "x-event": payload.eventCode,
    },
  });

export const StopBadgeCollection = async (payload) =>
  await axios.post(API + "/scan/badge-collection/stop", payload.payload, {
    headers: {
      "x-organization": process.env.REACT_APP_ORGANIZATION,
      "x-event": payload.eventCode,
    },
  });
export const GetEvents = async (payload) =>
  await axios.post(`${API}/scan/event`, payload, HEADER);
