import { useEffect } from "react";
import { usePrevious } from "./usePreavious";

export const useOnChange = (currentValue, callback) => {
  const previousValue = usePrevious(currentValue);

  useEffect(() => {
    if (previousValue !== currentValue) {
      callback(previousValue, currentValue);
    }
  }, [callback, currentValue, previousValue]);
};
