import { useCallback } from "react";
import { QrReaderVideo } from "./QrReaderVideo";

export const QRCodeInput = ({
  disabled,
  onChange,
  className,
  scanDelay,
  constraints,
  style,
}) => {
  const handleResult = useCallback(
    (result) => {
      !disabled && onChange(result.getText());
    },
    [disabled, onChange]
  );

  return (
    <QrReaderVideo
      className={className}
      constraints={constraints}
      onResult={handleResult}
      scanDelay={scanDelay}
      disabled={disabled}
      style={style}
    />
  );
};
