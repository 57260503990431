import React, { useMemo } from "react";
import classes from "./toolbar.module.css";
// import AuthContext from "../../../context/auth-context";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useBreakPoint } from "../../../hook/useBreakPoint";

const Toolbar = () => {
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);
  // const show = useContext(AuthContext);

  return (
    <div className="row mx-0">
      {/* <span className={classes.UserName}>{show.user}</span> */}
      <div
        className={[
          classes.header,
          "p-4",
          "d-flex justify-content-between",
        ].join(" ")}
      >
        <div>
          <img
            style={{
              width: isMobile ? "250px" : "300px",
            }}
            src="./assets/images/solaax.png"
            alt="Logo"
            aria-label="logo"
          />
        </div>

        <div className="d-flex flex-column justify-content-end">
          <p className={classes.buildVersion}>
            BUILD {process.env.REACT_APP_BUILD}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
