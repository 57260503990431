import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as scanner from "../../pages/redux/ScannerRedux";
import * as scannerDetails from "../../pages/redux/ScannerDetailsRedux";
import * as toasterModal from "../../_redux/ToasterRedux";
export const rootReducer = combineReducers({
  scanner: scanner.reducer,
  scannerDetails: scannerDetails.reducer,
  toasterModal: toasterModal.reducer,
});

export function* rootSaga() {
  yield all([scanner.saga()]);
}
