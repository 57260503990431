import { useEffect, useMemo, useRef, useState } from "react";
import { BrowserQRCodeReader } from "@zxing/browser";
import { useOnChange } from "../../../hook/useOnChange";
import { useOnMount } from "../../../hook/useOnMount";
import { toasterActions } from "../../../_redux/ToasterRedux";
import { useDispatch } from "react-redux";

export const useQrReader = ({
  scanDelay,
  constraints: video,
  onResult,
  disabled,
}) => {
  const [result, setResult] = useState();
  const controlsRef = useRef(null);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const codeReader = useRef(
    new BrowserQRCodeReader(undefined, {
      delayBetweenScanAttempts: scanDelay,
      delayBetweenScanSuccess: scanDelay,
    })
  );

  useOnChange(result, () => {
    if (!disabled && result) {
      controlsRef.current?.stop();
      onResult(result);
    }
  });

  useOnMount(() => {
    if (!isMediaDevicesSupported()) {
      dispatch(
        toasterActions.toaster.set({
          variant: "danger",
          message: "Camera can't be loaded, Please try again!",
          delay: 10000,
        })
      );
    }
  });

  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl && isMediaDevicesSupported()) {
      const callback = (result) => {
        if (result) {
          setResult(result);
        }
      };

      if (
        codeReader &&
        codeReader.current &&
        codeReader.current.decodeFromConstraints
      )
        codeReader.current
          ?.decodeFromConstraints({ video: video }, videoEl, callback)
          .then((controls) => {
            controlsRef.current = controls;
          })
          .catch((e) => {
            dispatch(
              toasterActions.toaster.set({
                variant: "danger",
                message: "Camera can't be loaded, Please try again!",
              })
            );
          });
    }
    return () => {
      controlsRef.current?.stop();
    };
  }, [dispatch, video]);

  return useMemo(() => ({ videoRef }), []);
};

export const isMediaDevicesSupported = () => {
  const isMediaDevicesSupported =
    typeof navigator !== "undefined" && !!navigator.mediaDevices;

  if (!isMediaDevicesSupported) {
    console.warn(
      `[ReactQrReader]: MediaDevices API has no support for your browser.`
    );
  }

  return isMediaDevicesSupported;
};
