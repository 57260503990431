import { useHistory } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { actions } from "../redux/ScannerRedux";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useCallback, useEffect } from "react";
import { useSocketIO } from "../../hook/useSocketIO";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { StopBadgeCollection } from "../redux/ScannerCRUD";
import { toasterActions } from "../../_redux/ToasterRedux";

export const CustomerDetailsPage = () => {
  const customer = useSelector((state) => state.scanner.customer);
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const history = useHistory();
  const dispatch = useDispatch();
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  useEffect(() => {
    document.getElementById("mainBox").style.overflowX = "auto";
    document.getElementById("mainBox").style.display = "flex";
    document.getElementById("mainBox").style.flexDirection = "column";
    document.getElementById("mainBox").style.justifyContent = "center";
  }, [history]);

  const stopBadge = useCallback(async () => {
    if (scanner.data && selectedEvent) {
      try {
        const dataBody = {
          payload: {
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        await StopBadgeCollection(dataBody);
      } catch (err) {
        dispatch(
          toasterActions.toaster.set({
            message: err.response,
            variant: "danger",
          })
        );
      }
    }
  }, [dispatch, scanner.data, selectedEvent]);

  useEffect(() => {
    if (!customer.data) {
      stopBadge();
      history.replace("/welcome");
    }
  }, [customer, history, stopBadge]);

  const socketCallback = useCallback(
    (data) => {
      if (data && data.qrCode === false) {
        dispatch(actions.customer.resetCustomer());
        history.push("/welcome");
      }
      if (data && data.code && scanner && scanner.data) {
        const dataBody = {
          payload: {
            customerCode: data.code,
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        dispatch(actions.customer.search(dataBody));
      }
    },
    [dispatch, history, scanner, selectedEvent]
  );

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  useSocketIO({ socketCallback, socketName });

  const products = useMemo(() => {
    return customer?.data?.products || [];
  }, [customer?.data?.products]);

  const image = useMemo(() => {
    return (
      <img
        width="100%"
        height="100%"
        alt="img-customer"
        src={
          customer && customer.data && customer.data.photo
            ? `${process.env.REACT_APP_STATIC_URL}/${customer.data.photo.slug}`
            : "/assets/images/default.png"
        }
        style={{
          objectFit: "cover",
        }}
      />
    );
  }, [customer]);

  const productName = useMemo(() => {
    return (
      products &&
      products.length &&
      products.map((item, idx, array) => {
        const isLast = idx === array.length - 1;
        return (
          <p
            key={idx + array.length}
            className="d-flex flex-column justify-content-center text-white py-3"
          >
            {item &&
              (item.slug || item.name) &&
              ` ${
                item.slug
                  ? item.slug.toLowerCase()
                  : item.name
                  ? item.name.toLowerCase()
                  : ""
              } ${!isLast ? ", " : ""}`}
          </p>
        );
      })
    );
  }, [products]);

  return (
    <>
      <div
        className="pb-3 position-relative mx-auto"
        style={{
          fontFamily: "sans-serif",
          width: "350px",
          backgroundColor: "white",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        {/* <img
          alt="solaax scanner"
          src="/assets/images/tedx-pattern.png"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "100%",
            zIndex: 0,
          }}
        /> */}
        {customer && customer.data && (
          <div
            className="position-relative d-flex flex-column gap-3"
            style={{
              zIndex: 1,
            }}
          >
            <div
              className="mx-auto"
              style={{
                width: "150px",
              }}
            >
              <div
                className="mx-auto"
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#29292B",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="solaax scanner"
                  src="/assets/images/solaax.png"
                  style={{
                    width: "90%",
                  }}
                />
              </div>
              <div
                className="position-relative"
                style={{
                  width: "100%",
                  height: "170px",
                }}
              >
                {/* <div
                  className="position-absolute"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#29292B",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: -1,
                  }}
                ></div> */}
                {image}
              </div>
              <div
                className="text-center text-white text-uppercase p-2 mx-auto"
                style={{
                  width: "100%",
                  fontWeight: "bolder",
                  backgroundColor: "#29292B",
                }}
              >
                {productName}
              </div>
            </div>
            <div className="px-4">
              <div className="d-flex flex-column justify-content-center">
                {customer.data.name && (
                  <p
                    className="mb-0 fs-sm-3 text-primary text-center"
                    style={{
                      fontSize: isMobile ? "2rem" : "2rem",
                      fontWeight: "bolder",
                    }}
                  >
                    {customer.data.salutation ? customer.data.salutation : ""}{" "}
                    {customer.data.name}
                  </p>
                )}

                {customer.data.designation && (
                  <p
                    className="mb-0 fs-sm-3 text-black text-end"
                    style={{
                      fontSize: isMobile ? "1.5rem" : "1.5rem",
                    }}
                  >
                    {customer.data.designation}
                  </p>
                )}

                {customer.data.customerOrganization && (
                  <p
                    className="mb-0 fs-sm-3 text-black text-end"
                    style={{
                      fontSize: isMobile ? "1.5rem" : "1.5rem",
                    }}
                  >
                    {customer.data.customerOrganization}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="px-4">
              <img
                alt="solaax scanner"
                src="/assets/images/solaax-black.png"
                style={{
                  width: "50%",
                }}
              />
            </div> */}
          </div>
        )}
        {/* {customer && customer.data && (
        <div
          className={clsx(
            "rounded d-flex flex-column p-3",
            isMobile ? "gap-5" : isiPad ? "gap-5" : "gap-2"
          )}
        >
          <div className="d-flex flex-row gap-3">
            <div
              style={{
                minWidth: isMobile ? "100px" : "170px",
              }}
            >
              {image}
            </div>
            <div className="d-flex flex-column justify-content-center">
              {customerName}
            </div>
          </div>
          <div>
            <div
              className="d-flex flex-column mt-3"
              style={{
                fontSize: isMobile ? "17px" : "30px",
              }}
            >
              {selectedDetails}
              <div
                className="mt-3 d-flex flex-row gap-2 text-secondary"
                style={{
                  textTransform: "capitalize",
                }}
              >
                <p className="me-2">
                  <i className={`me-3 bi text-primary bi-person-workspace`}></i>
                  Category:
                </p>

                {products &&
                  products.length &&
                  products.map((item, idx, array) => {
                    const isLast = idx === array.length - 1;
                    return (
                      <p className="d-flex flex-column justify-content-center">
                        {(item.slug || item.name) &&
                          ` ${
                            item.slug
                              ? item.slug.toLowerCase()
                              : item.name
                              ? item.name.toLowerCase()
                              : ""
                          } ${!isLast ? ", " : ""}`}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )} */}
      </div>
      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name} / welcome`}
          // onClick={() => {
          //   setTimeout(() => {
          //     dispatch(actions.customer.resetCustomer());
          //     history.push("/welcome");
          //   }, 500);
          // }}
        />
      )}
    </>
  );
};
