import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { useBreakPoint } from "../../../../hook/useBreakPoint";
export const ResultHeader = ({
  result: { icon, background },
  scanResult: { outcome, is_rfid, ticketCode },
}) => {
  const { down } = useBreakPoint();
  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  return (
    <div className="mx-0 px-0">
      <div
        className={`d-flex w-100 flex-row px-3 py-4 justify-content-between accepted bg-${background}`}
      >
        <div className="d-flex w-100 flex-row">
          <div className="mr-4 d-flex flex-column justify-content-center">
            <img
              height={isMobile ? "40px" : "80px"}
              src={
                icon
                  ? "./assets/images/granted.svg"
                  : "./assets/images/invalid.svg"
              }
              alt="result"
            />
          </div>
          <div className="px-0 d-flex flex-column justify-content-center">
            <p className="ps-2 text-light fs-1 fw-bolder">{outcome}</p>
          </div>
        </div>
        {!is_rfid && ticketCode && (
          <div className="px-0 d-flex flex-column justify-content-center">
            <div
              className="py-2"
              style={{
                background: "white",
                borderRadius: "5px",
                paddingRight: "12px",
                paddingLeft: "7px",
              }}
            >
              <QRCode value={ticketCode} size={isMobile ? 30 : 60} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
