import React, { useContext } from "react";
import authContext from "../../context/auth-context";
import classes from "./back-button.module.css";

export const BackButton = ({ handleClick = () => {} }) => {
  const show = useContext(authContext);
  return (
    <>
      {show.showBackButton ? (
        <button
          className={[
            classes.BackButton,
            classes.BackButtonRight,
            "rounded-circle",
          ].join(" ")}
          onTouchStart={handleClick}
          onClick={handleClick}
        >
          <p
            className="position-absolute fs-5"
            style={{
              left: "42%",
              top: "76%",
            }}
          >
            Back
          </p>
        </button>
      ) : null}
      {show.showBackButton ? (
        <button
          className={[
            classes.BackButton,
            classes.BackButtonLeft,
            "rounded-circle",
          ].join(" ")}
          onTouchStart={handleClick}
          onClick={handleClick}
        >
          <p
            className="position-absolute fs-5"
            style={{
              right: "42%",
              top: "76%",
            }}
          >
            Back
          </p>
        </button>
      ) : null}
    </>
  );
};
