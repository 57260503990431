import React from "react"

const authContext = React.createContext({
    showLogout : false ,
    setLogoutCtxt : () => {} ,
    showBackButton : false ,
    setBackButtonCtxt : () => {},
    netWorkStatus : false ,
    setNetWorkStatusCtxt : () => {},
    user : '' ,
    setuserCtxt : () => {}
})
export default authContext;
