import { useState, useMemo, useCallback } from "react";
import { debounce } from "lodash";
import { useOnResize } from "./useOnResize";

const resolveBreakpoint = (width) => {
  if (width < 576) {
    return "xs";
  } else if (width >= 576 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 992) {
    return "md";
  } else if (width >= 992 && width < 1200) {
    return "lg";
  } else if (width >= 1200 && width < 1440) {
    return "xl";
  }
  return "xxl";
};

export const useBreakPoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  const resizeHandler = useMemo(() => {
    return debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
  }, []);

  useOnResize(resizeHandler);

  const down = useCallback(
    (breakpoint) => {
      const a = breakpointRanking.indexOf(breakpoint);
      const b = breakpointRanking.indexOf(size);

      return b <= a;
    },
    [size]
  );

  const up = useCallback(
    (breakpoint) => {
      const a = breakpointRanking.indexOf(breakpoint);
      const b = breakpointRanking.indexOf(size);

      return b >= a;
    },
    [size]
  );

  return { size, up, down };
};

const breakpointRanking = ["xs", "sm", "md", "lg", "xl", "xxl"];

export const MOBILE_BREAKPOINTS = ["xs", "sm", "md"];
