import { createContext, useContext, useEffect, useMemo, useState } from "react";
import socketIOClient from "socket.io-client";

const Context = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const WSS_URL = useMemo(() => {
    return process.env.REACT_APP_WSS_URL;
  }, []);

  const [socket, setSocket] = useState(
    WSS_URL
      ? socketIOClient(WSS_URL, {
          transports: ["websocket", "polling"],
        })
      : null
  );
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };
    const onDisconnect = () => {
      setIsConnected(false);
    };
    const onError = () => {
      setIsConnected(false);
    };

    socket?.on("connect", onConnect);
    socket?.on("disconnect", onDisconnect);
    socket?.on("connect_error", onError);

    return () => {
      socket?.off("connect", onConnect);
      socket?.off("disconnect", onDisconnect);
      socket?.off("connect_error", onError);
    };
  }, [socket]);

  const value = useMemo(() => {
    return {
      socket,
      isConnected,
    };
  }, [isConnected, socket]);

  useEffect(() => {
    setSocket(
      socketIOClient(WSS_URL, {
        transports: ["websocket", "polling"],
      })
    );
  }, [WSS_URL]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useWebSocket = () => {
  const socket = useContext(Context);
  if (!socket) {
    throw new Error("socket provider is not present.");
  }
  return socket;
};
