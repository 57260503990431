import { useMemo, useState, useCallback } from "react";
import { useBreakPoint } from "../../hook/useBreakPoint";
import { useSelector } from "react-redux";
import { SectionTitle } from "../section-title";
import { QRCodeInput } from "./QrReaderInput";
import clsx from "clsx";

export const QRCodeReader = ({ handleScan, result, error, isLoading }) => {
  const [camera, setCamera] = useState({ facingMode: "environment" });
  const scanner = useSelector((state) => state.scanner.scanner);

  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);

  const isiPad = useMemo(() => {
    return down("md");
  }, [down]);

  const switchCamera = useCallback(() => {
    if (camera.facingMode === "user") setCamera({ facingMode: "environment" });
    else setCamera({ facingMode: "user" });
  }, [camera]);

  const switchCameraImage = useMemo(() => {
    return (
      <img
        className="bg-light position-absolute border rounded-circle border-light switchCamera"
        src="./assets/images/switchCamera.png"
        onPointerDown={switchCamera}
        alt="switchCamera"
      />
    );
  }, [switchCamera]);

  const isBadgeCollection = useMemo(() => {
    return scanner && scanner.data && scanner.data.type === "badge-collection";
  }, [scanner]);

  const isNotBadgeCollection = useMemo(() => {
    return scanner && scanner.data && scanner.data.type !== "badge-collection";
  }, [scanner]);

  const constraints = useMemo(() => {
    return isBadgeCollection ? { facingMode: "user" } : camera;
  }, [camera, isBadgeCollection]);

  const qr = useMemo(() => {
    return (
      <QRCodeInput
        disabled={isLoading}
        scanDelay={600}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        onChange={handleScan}
        constraints={constraints}
      />
    );
  }, [constraints, handleScan, isLoading]);

  return (
    <div className="row mx-0 padding d-block position-absolute top-25 start-50 translate-middle-x">
      <SectionTitle name="Scan QR Code" />
      <div className="col-12 px-0 mt-5 justify-content-center text-center">
        <div
          className={clsx(
            "rounded m-auto overflow-hidden position-relative qr-code_class d-flex flex-column justify-content-center"
          )}
          style={{
            width: isMobile ? "300px" : isiPad ? "600px" : "400px",
            aspectRatio: "1 / 1",
          }}
        >
          <div
            className={clsx("overflow-hidden m-auto")}
            style={{
              width: "90%",
              height: "90%",
            }}
          >
            {qr}
          </div>

          {isNotBadgeCollection && switchCameraImage}
        </div>
        <p className="fs-4 text-light mt-3">
          {isLoading && (
            <span className="indicator-progress">
              <span className="spinner-border spinner-border-sm align-middle me-2 text-warning"></span>
            </span>
          )}
          {result}
        </p>
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </div>
  );
};
