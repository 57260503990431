import React, { useCallback, useState } from "react";
import { InputField } from "../input-field";
import { CustomButton } from "../custom-button/CustomButton";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

export const PinModal = ({ show = false, handleClose, onSubmit }) => {
  const [code, setCode] = useState({
    password: "",
    wrong: false,
  });

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setCode((previous) => {
      return {
        ...previous,
        [name]: value,
      };
    });
  }, []);

  const handleSubmitCode = useCallback(
    (event) => {
      event.preventDefault();
      if (code.password === "123456") {
        const confirm = setTimeout(() => {
          onSubmit();
          setCode({ password: "", wrong: false });
          clearTimeout(confirm);
        }, 300);
      } else {
        setCode({ password: "", wrong: true });
      }
    },
    [code.password, onSubmit]
  );

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="d-block">
          <Modal.Title className="text-light">
            <div className="w-100 d-flex flex-row justify-content-between">
              <div className="flex-grow-1">
                <p className="fs-5 text-start mt-2 ">Enter the PIN code</p>
              </div>
              <div>
                <button
                  className="btn btn-lg p-0"
                  aria-label="Close"
                  onClick={() => {
                    setCode({ password: "", wrong: false });
                    handleClose();
                  }}
                >
                  <i className="bi bi-x fs-1 text-light"></i>
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitCode}>
            <div className="px-0 mx-0">
              <div className="mt-3">
                <InputField
                  id="password"
                  type="password"
                  value={code.password}
                  errorMessage={code.wrong ? "Wrong Code" : null}
                  placeholder="Your code"
                  onChange={handleChange}
                  isTouched={true}
                />
              </div>
              <div className="mt-3 px-0 text-center">
                <CustomButton type="submit" label="submitCode" title="Ok" />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

PinModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PinModal.defaultProps = {
  show: false,
};
