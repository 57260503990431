import { useQrReader } from "./hook/useQrReader";

export const QrReaderVideo = ({
  constraints,
  className,
  onResult,
  scanDelay,
  disabled,
  style,
}) => {
  const { videoRef } = useQrReader({
    constraints,
    scanDelay,
    onResult,
    disabled,
  });

  return (
    videoRef && (
      <video
        ref={videoRef}
        controls={false}
        playsInline
        autoPlay
        className={className}
        muted
        style={style}
      />
    )
  );
};
