import { useEffect } from "react";
import { useWebSocket } from "./SocketProvider";
export const useSocketIO = ({ socketCallback, socketName }) => {
  const { socket } = useWebSocket();

  useEffect(() => {
    if (socketName) {
      const callback = (data) => {
        if (data) socketCallback(data);
      };
      socket.on(socketName, callback);
      return () => {
        socket?.off(socketName, callback);
      };
    }
  }, [socket, socketCallback, socketName]);
};
