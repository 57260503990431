import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Layout from "../components/layout";
import { CheckinScannerResult } from "../pages/scanner-validator/check-scanner-result/CheckinScannerResult";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "../setup/redux/Store";
import { ScannerPage } from "../pages/ScannerPage";
import { WelcomePage } from "../pages/badge-collection/WelcomePage";
import { EventsPage } from "../pages/EventsPage";
import { BadgeCollectionQrCodePage } from "../pages/badge-collection/BadgeCollectionQrCodePage";
import { CustomerDetailsPage } from "../pages/badge-collection/CustomerDetailsPage";
import { BadgeCollectionRFIDPage } from "../pages/badge-collection/BadgeCollectionRFIDPage";
import { ScanAgainPage } from "../pages/ScanAgainPage";
import { ScannerValidatorQRCodePage } from "../pages/scanner-validator/ScannerValidatorQRCodePage";
import { ScannerValidatorRFIDPage } from "../pages/scanner-validator/ScannerValidatorRFIDPage";
import { PointsPage } from "../pages/PointsPage";
import { CustomerPoratlInvalidData } from "../pages/badge-collection/CustomerPortalInvalidData";
import { WebSocketProvider } from "../hook/SocketProvider";
function App() {
  useEffect(() => {
    document.ontouchmove = function (event) {
      event.preventDefault();
    };
  }, []);

  const { PUBLIC_URL } = process.env;
  return (
    <Provider store={store}>
      <WebSocketProvider>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename={PUBLIC_URL}>
            <Layout>
              <Switch>
                <Route exact path="/scanner" component={ScannerPage} />
                <Route exact path="/events" component={EventsPage} />
                <Route exact path="/try-again" component={ScanAgainPage} />
                {/* BADGE COLLECTION */}
                <Route exact path="/welcome" component={WelcomePage} />
                <Route
                  exact
                  path="/badge-collection-scanner"
                  component={BadgeCollectionQrCodePage}
                />
                <Route
                  exact
                  path="/badge-collection-rfid"
                  component={BadgeCollectionRFIDPage}
                />
                <Route
                  exact
                  path="/customer-details"
                  component={CustomerDetailsPage}
                />

                <Route
                  exact
                  path="/customer-invalid/:code"
                  component={CustomerPoratlInvalidData}
                />
                {/* SCANNER AND VALIDATOR */}
                <Route
                  exact
                  path="/qrscan"
                  component={ScannerValidatorQRCodePage}
                />
                <Route
                  exact
                  path="/rfid"
                  component={ScannerValidatorRFIDPage}
                />
                <Route exact path="/points" component={PointsPage} />

                <Route
                  exact
                  path="/scanner-result"
                  component={CheckinScannerResult}
                />

                <Redirect from="/*" to="/scanner" />
              </Switch>
            </Layout>
          </BrowserRouter>
        </PersistGate>
      </WebSocketProvider>
    </Provider>
  );
}

export default App;
