import { useHistory, useRouteMatch } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useSocketIO } from "../../hook/useSocketIO";
export const CustomerPoratlInvalidData = () => {
  const {
    params: { code },
  } = useRouteMatch();
  const history = useHistory();
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  const socketCallback = useCallback(
    (data) => {
      if (data.qrCode === false) {
        history.push("/welcome");
      }
    },
    [history]
  );

  useSocketIO({ socketCallback, socketName });

  const image = useMemo(() => {
    return <img src="/assets/images/invalid.svg" width="60px" alt="invalid" />;
  }, []);

  return (
    <div className="position-absolute top-50 start-50 translate-middle w-100">
      <p className="fs-4 text-light p-5 text-center">{code}</p>

      <div className="w-100 bg-danger py-5">
        <div className="text-light fs-1 d-flex gap-3 d-flex justify-content-center">
          {image}
          <p
            className="d-flex flex-column justify-content-center"
            style={{
              fontSize: "45px",
            }}
          >
            Invalid QR Code
          </p>
        </div>
      </div>

      {/* <div>
        <p className="p-5 text-danger">
          <h3>Please visit the help desk!</h3>
        </p>
      </div> */}
    </div>
  );
};
