import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  setResult: "[SCANNER] SET SCANNER RESULT",
  clearResult: "[SCANNER] CLEAR SCANNER RESULT",
};

const initialState = {
  scanResult: {
    is_rfid: undefined,
    ticketCode: undefined,
    message: undefined,
    outcome: undefined,
    gateLocations: undefined,
    checkIn: false,
    checkOut: false,
    productName: undefined,
    visitorName: undefined,
    visitorPhoto: undefined,
    organization: undefined,
    nationality: undefined,
    position: undefined,
    category: undefined,
    title: undefined,
  },
};
export const reducer = persistReducer(
  {
    storage,
    key: "v2-scan-details",
    whitelist: ["scanResult"],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.setResult: {
        const scanResult = action.payload;
        return { ...state, scanResult: scanResult };
      }

      case actionTypes.clearResult: {
        return initialState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setResult: (data) => ({
    type: actionTypes.setResult,
    payload: data,
  }),

  clearResult: () => ({
    type: actionTypes.clearResult,
  }),
};
