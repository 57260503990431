import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "../redux/ScannerRedux";
import { Footer } from "../../components/footer/Footer";
import { useIdleTimer } from "react-idle-timer";
import { QRCodeReader } from "../../components/qr-code/QRCodeReader";
import { StopBadgeCollection } from "../redux/ScannerCRUD";
import { toasterActions } from "../../_redux/ToasterRedux";
import { useSocketIO } from "../../hook/useSocketIO";
const timeout = 30000;

export const BadgeCollectionQrCodePage = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const scanner = useSelector((state) => state.scanner.scanner);
  const customer = useSelector((state) => state.scanner.customer);
  const isRFID = useSelector((state) => state.scanner.isRFID);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const [qrcode, setQrcode] = useState();
  const history = useHistory();
  const [result, setResult] = useState("");

  const handleOnIdle = useCallback(async () => {
    if (scanner && scanner.data && selectedEvent) {
      try {
        const dataBody = {
          payload: {
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        await StopBadgeCollection(dataBody);
        history.replace("/welcome");
      } catch (err) {
        dispatch(
          toasterActions.toaster.set({
            message: err.response,
            variant: "danger",
          })
        );
      }
    } else {
      dispatch(
        toasterActions.toaster.set({
          variant: "danger",
          message: "Error",
        })
      );
      history.replace("/scanner");
    }
  }, [dispatch, history, scanner, selectedEvent]);

  const { pause } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  const socketCallback = useCallback(
    (data) => {
      if (data.qrCode === false) {
        pause();
        history.push("/welcome");
      }
    },
    [history, pause]
  );

  useSocketIO({ socketCallback, socketName });

  useEffect(() => {
    dispatch(actions.customer.resetCustomer());
  }, [dispatch]);

  useEffect(() => {
    if (customer && customer.data) {
      setIsSubmitting(false);
      history.push("/customer-details");
    }

    if (customer && customer.error && qrcode) {
      setIsSubmitting(false);
      dispatch(actions.customer.resetCustomer());
      dispatch(
        toasterActions.toaster.set({
          message: customer.error,
          variant: "danger",
          show: true,
        })
      );
      history.push(`/customer-invalid/${qrcode}`);
    }
  }, [dispatch, history, customer, qrcode]);

  useEffect(() => {
    if (!selectedEvent) {
      history.replace("/events");
    }
  }, [history, selectedEvent]);

  const checkResult = useCallback(
    async (debounceCode) => {
      if (
        scanner &&
        scanner.data &&
        selectedEvent &&
        debounceCode &&
        !isSubmitting
      ) {
        setQrcode(debounceCode);
        setIsSubmitting(true);
        const dataBody = {
          payload: {
            customerCode: debounceCode.trim(),
            gateCode: scanner.data.code,
          },
          eventCode: selectedEvent.code,
        };
        dispatch(actions.customer.search(dataBody));
      }
    },
    [scanner, selectedEvent, isSubmitting, dispatch]
  );

  const handleScan = useCallback(
    async (data) => {
      if (data) {
        let qrcode;

        try {
          if (data.includes("?q=")) {
            const url = new URL(data);
            qrcode = new URLSearchParams(url.search).get("q");
          } else {
            qrcode = data;
          }
          if (qrcode) {
            setResult(qrcode);
            checkResult(qrcode);
          }
        } catch (err) {
          dispatch(
            toasterActions.toaster.set({
              variant: "danger",
              message: "Error",
            })
          );
        }
      }
    },
    [checkResult, dispatch]
  );

  return (
    <>
      <QRCodeReader
        isLoading={isSubmitting}
        handleScan={handleScan}
        result={result}
      />
      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name} / ${selectedEvent.name} / welcome`}
        />
      )}

      {isRFID && (
        <div
          className="position-fixed bottom-0 end-0 m-3 scan-with-qrcode-or-rfid fs-5"
          onClick={() => {
            history.push("/badge-collection-rfid");
          }}
        >
          Scan with RFID
        </div>
      )}
    </>
  );
};
