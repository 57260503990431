import React, { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { BoxNameArrow } from "../components/box-name-arrow";
import { SectionTitle } from "../components/section-title";
import { useSocketIO } from "../hook/useSocketIO";
export const ScanAgainPage = () => {
  const history = useHistory();
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const scanner = useSelector((state) => state.scanner.scanner);
  const scannerPath = useSelector((state) => state.scanner.scannerPath);

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  const socketCallback = useCallback(
    (data) => {
      if (data.qrCode) history.push(scannerPath);
    },
    [history, scannerPath]
  );

  useSocketIO({ socketCallback, socketName });

  return (
    <div
      className="row mx-0 mt-4 padding"
      onClick={() => {
        if (
          scanner &&
          scanner.data &&
          scanner.data.type !== "badge-collection"
        ) {
          setTimeout(() => {
            history.replace(scannerPath);
          }, 500);
        }
      }}
    >
      <SectionTitle name="Stand By Mode" />
      <div className="col-12 px-0 row mx-0 mt-5 justify-content-center">
        <BoxNameArrow name="Scan Ticket" />
      </div>
    </div>
  );
};
